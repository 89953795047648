import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

class Footer extends React.Component {
    constructor(props) {
      super(props);
    }
    render() {
      const { t } = this.props;
      return (
        <div>
        <footer className="pb-5 pt-5">
           <div className="container">
             <div className="row">
               <div className="col-md-9 col-lg-9">
                 <img className="footer_logo" src="img/logo.png" alt="" />
                 <hr />
                 <h3 className="white_text story_text">{t('footer.Footer_Content')}</h3>
               </div>
               <div className="col-md-3 col-lg-3 middle_text">
                 <p className="copy_text">{t('footer.Copyright_Text')}</p>
               </div>
             </div>
           </div>
         </footer>
    
         <div className="overlay">
           <div className="overlayDoor"></div>
           <div className="overlayContent">
             <img src="img/logo.png" alt="" />
             <p>Loading.....</p>
           </div>
         </div>
    
       <a id="return-to-top"><i className="fa fa-arrow-up"></i></a>
    
      </div>
      );
    }
  }

  export default withTranslation()(Footer);